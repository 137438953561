import React from 'react'
import { Feature } from '../../components'
import './features.css'

const featuresData = [
    {
        title: 'Lorem ipsum dolor sit.',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores accusantium maxime atque.'
    },
    {
        title: 'Lorem ipsum dolor sit.',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores accusantium maxime atque.'
    },
    {
        title: 'Lorem ipsum dolor sit.',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores accusantium maxime atque.'
    },
    {
        title: 'Lorem ipsum dolor sit.',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores accusantium maxime atque.'
    },
]

function Features() {
    return (
        <div className='gpt3__features section__padding' id='features'>
            <div className="gpt3__features-heading">
                <h1 className="gradient__text">
                    The Future is Now and You Just Need To Realize it. Step into Future Today and Make it Happen.
                </h1>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </p>
            </div>
            <div className="gpt3__features-container">
                {featuresData.map((item, index) => (
                    <Feature title = {item.title} text={item.text}/>
                ))}
            </div>
        </div>
    )
}

export default Features
