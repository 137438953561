import React from "react";
import "./header.css";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";

function Header() {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>
          Let's Build Something amazing with GPT-3 OpenAI
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet
          quisquam exercitationem libero optio iste assumenda, porro et fuga
          iure voluptates illum beatae nulla quis aliquid iusto possimus
          provident voluptatibus modi?
        </p>

        <div className='gpt3__header-content__input'>
          <input type='email' placeholder='Your Email Address' />
          <button type='button'>Get Started</button>
        </div>
        <div className='gpt3__header-content__people'>
          <img src={people} alt='people' />
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit</p>
        </div>
      </div>
      <div className='gpt3__header-image'>
        <img src={`http://openai-ui.webdevgroupid.com${ai}`} alt='ai' />
      </div>
    </div>
  );
}

export default Header;
