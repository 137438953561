import React from 'react'
import { Feature } from '../../components'
import './whatGPT3.css'

function WhatGPT3() {
    return (
        <div className='gpt3__whatgpt3 section__margin' id='wgpt3'>
            <div className="gpt3__whatgpt3-feature">
                <Feature title="What is GPT-3" text= "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur blanditiis incidunt quam, consequatur facere fuga odio quasi excepturi, sequi cupiditate molestias. Corporis omnis quod dicta ab, suscipit esse qui commodi." />
            </div>
            <div className="gpt3__whatgpt3-heading">
                <h1 className="gradient__text">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt?
                </h1>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
            </div>
            <div className="gpt3__whatgpt3-container">
                <Feature title= "Chatbots" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, nisi quidem. Aperiam, rerum unde?" />
                <Feature title= "Knowledgesabe" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, nisi quidem. Aperiam, rerum unde?"/>
                <Feature title= "Education" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, nisi quidem. Aperiam, rerum unde?"/>
            </div>
        </div>
    )
}

export default WhatGPT3
