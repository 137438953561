import React from 'react'
import './cta.css'

function CTA() {
    return (
        <div className='gpt3__cta '>
            <div className="gpt3__cta-content">
                <p>Lorem ipsum dolor sit amet consectetur.</p>
                <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
            </div>
            <div className="gpt3__cta-btn">
                <button type='button'>Get Started</button>
            </div>
        </div>
    )
}

export default CTA
