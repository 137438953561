import React from 'react'
import './footer.css'
import gpt3Logo from '../../assets/logo.svg'

function  Footer() {
    return (
        <div className='gpt3__footer section__padding'>
            <div className="gpt3__footer-heading">
                <h1 className="gradient__text">Do you want to step in to the future before others.</h1>
            </div>

                <div className="gpt3__footer-btn">
                    <p>Request Early Access</p>
                </div>

                <div className="gpt3__footer-links">
                    <div className="gpt3__footer-links_logo">
                        <img src={gpt3Logo} alt="logo" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    </div>
                    <div className="gpt3__footer-links_div">
                        <h4>Links</h4>
                        <p>Overons</p>
                        <p>Social Media</p>
                        <p>Counters</p>
                        <p>Contact</p>
                    </div>
                    <div className="gpt3__footer-links_div">
                        <h4>Company</h4>
                        <p>Terms</p>
                        <p>Privacy Policy</p>
                        <p>Contact</p>
                    </div>
                    <div className="gpt3__footer-links_div">
                        <h4>Get in touch</h4>
                        <p>Lorem ipsum dolor sit amet.</p>
                        <p>Social Media</p>
                        <p>088-81123</p>
                        <p>info@gmail.com</p>
                    </div>
                </div>

                <div className="gpt3__footer-copyright">
                    <p>@ code-steady 2021</p>
                </div>
        </div>
    )
}

export default Footer
